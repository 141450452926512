import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPromotions(ctx, data) {
      return useJwt.getPromotions(data).then(response => response);
    },
    createDiscount(ctx, data) {
      return useJwt.createDiscount(data).then(response => response);
    },
    updateDiscount(ctx, data) {
      return useJwt.updateDiscount(data).then(response => response);
    },
    deleteDiscount(ctx, id) {
      return useJwt.deleteDiscount(id).then(response => response);
    },
  },
};
